const IconPhone = props => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		{...props}
		width="28"
		height="28"
		viewBox="0 0 28 28"
		fill="none"
	>
		<path
			d="M26.288 8.75199C25.648 7.08799 24.624 5.67999 23.472 4.52799C22.32 3.37599 20.912 2.35199 19.248 1.71199C17.584 1.07199 15.92 0.687988 14 0.687988C12.208 0.687988 10.416 1.07199 8.75199 1.71199C7.21599 2.47999 5.80799 3.37599 4.52799 4.52799C3.37599 5.67999 2.35199 7.08799 1.71199 8.75199C1.07199 10.416 0.687988 12.08 0.687988 14C0.687988 15.792 1.07199 17.584 1.71199 19.248C2.35199 20.912 3.37599 22.32 4.52799 23.472C5.67999 24.624 7.08799 25.648 8.75199 26.288C10.416 26.928 12.08 27.312 14 27.312C15.792 27.312 17.584 26.928 19.248 26.288C20.912 25.648 22.32 24.624 23.472 23.472C24.624 22.32 25.648 20.912 26.288 19.248C26.928 17.584 27.312 15.92 27.312 14C27.312 12.208 26.928 10.416 26.288 8.75199ZM21.04 18.992L20.4 19.632C20.144 19.888 19.888 20.016 19.504 20.144C19.248 20.272 18.864 20.4 18.608 20.4C18.48 20.4 15.152 20.656 11.184 16.688C8.23999 13.744 7.21599 11.568 7.47199 9.26399C7.47199 9.00799 7.59999 8.62399 7.72799 8.36799C7.85599 8.11199 7.98399 7.72799 8.23999 7.47199L8.87999 6.83199C9.39199 6.31999 10.032 6.31999 10.416 6.83199L12.592 9.00799C12.976 9.39199 12.976 10.16 12.592 10.544L12.08 10.8L11.312 11.568C11.312 11.568 11.44 11.696 11.44 11.824C11.824 12.464 12.336 13.488 13.36 14.512C14.384 15.536 15.28 16.048 16.048 16.432L16.304 16.56L17.328 15.536C17.84 15.024 18.48 15.024 18.864 15.536L21.04 17.712C21.424 17.84 21.424 18.48 21.04 18.992Z"
			fill="currentColor"
		/>
	</svg>
);

export default IconPhone;
