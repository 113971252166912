const IconArrowLeft = props => (
	<svg width="8" height="14" viewBox="0 0 8 14" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M7.92333 1.51333L6.74333 0.333328L0.0766602 7L6.74333 13.6667L7.92333 12.4867L2.43666 7L7.92333 1.51333Z"
			fill="currentColor"
		/>
	</svg>
);

export default IconArrowLeft;
